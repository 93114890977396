import { graphql } from 'gatsby'
import React from 'react'
import { SeoData } from '../../../../../src/common/types/Seo'
import Asistente from '../../../../../src/common/components/aireAcondicionado/Asistente'
import { useSelectedMenu } from '../../../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function AsistenteAireAcondicionado({ data }: Props) {
  useSelectedMenu(MenuPrincipal.COCHE)
  return <Asistente seoData={data.seoData} />
}

export const pageQuery = graphql`
  query asistenteAireAcondicionadoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
